h4 {
  /* margin-block-start: 1rem;
  margin-block-end: 1rem; */
  margin-top: 0px;
}

body {
  margin: 0px;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 0.25rem;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--vel-color-secondary-400);
  border-radius: 0.25rem;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

a {
  text-decoration: none;
  color: #2d2d2d;
}

.bg-image {
  background-image: url("assets/img/large_mountains.png");
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: inherit;
  width: inherit;
}

.main-dialog {
  padding: 1.5rem;
  min-width: 25rem;
  /* height: 44.5625rem;
  width: 24.875rem; */
}

.pac-container {
  z-index: 10000 !important;
}

.form-label {
  /* font-family: "Source Sans Pro"; */
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.25rem;
}

.form-control {
  border-radius: 0.25rem !important;
  border: 1px solid var(--ayvens-theme-neutral-neutral-600, #757777) !important;
  background: var(--ayvens-theme-neutral-neutral-white, #fff) !important;
}
/* 
.form-button {
  border-radius: 1.875rem !important;
  background: var(--ayvens-theme-brand-secondary-400, #0f3549) !important;
} */

.secondary-link {
  color: #008282 !important;
  text-decoration: none !important;
  font-weight: 700;
}

.form-divider {
  height: 0.0625rem;
  align-self: stretch;
  background-color: #dddddd;
}

.w-100 {
  width: 100%;
}

.w-50 {
  width: 50%;
}

.w-75 {
  width: 75%;
}

.w-25 {
  width: 25%;
}

.mb-05 {
  margin-bottom: 0.5rem;
}

.mb-1 {
  margin-bottom: 1rem;
}

.mb-2 {
  margin-bottom: 2rem;
}

.mt-05 {
  margin-top: 0.5rem;
}

.mt-1 {
  margin-top: 1rem;
}

.mt-2 {
  margin-top: 2rem;
}

.ml-1 {
  margin-left: 1rem;
}

.ml-2 {
  margin-left: 2rem;
}

.mr-1 {
  margin-right: 1rem !important;
}

.mr-2 {
  margin-right: 2rem;
}

.mt-5 {
  margin-top: 5rem !important;
}

.mt-10 {
  margin-top: 10rem !important;
}

.mb-5 {
  margin-bottom: 5rem !important;
}

.mb-10 {
  margin-bottom: 10rem !important;
}

.me-6 {
  margin-bottom: 6rem !important;
}

.text-center {
  text-align: center;
}

.card-container {
  background-color: #f6f6f6 !important;
  margin-bottom: 1rem !important;
}

.card-container-body {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.card-container-title {
  margin-bottom: 0.5rem;
}

.card-container:hover {
  background-color: #cfd7db !important;
  cursor: pointer;
}

.active {
  background-color: var(--vel-color-secondary-400) !important;
  color: #ffffff !important;
}

.active:hover {
  background-color: var(--vel-color-secondary-400) !important;
  cursor: not-allowed !important;
}

.pictogram {
  width: 3rem;
  height: 3rem;
  margin: 0.25rem;
}

.main-language-container {
  min-width: 25rem;
}

.main-language-selector {
  display: inline-flex;
  padding: 0.625rem 1.5rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.625rem;
  border-radius: 0.3125rem;
  background-color: #ffffff !important;
  margin-top: 2.25rem;
  margin-bottom: 2.25rem;
}

.map-container {
  border-radius: 0.75rem;
  height: calc(100vh - 260px);
}

.map-drawer-content-container {
  background-color: #ffffff;
  width: 50%;
}

.map-full-screen-container {
  padding-top: 0.75rem !important;
  overflow-y: hidden !important;
}

.map-layout-container {
  margin-top: 0px !important;
  margin-bottom: -1rem !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  margin-block: 0px !important;
  overflow-y: auto !important;
}

.map-main-content-container {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  margin-block: 0px !important;

  /* margin-top: 1rem !important; */
  /* padding-top: 1.5rem;
  padding-bottom: 1rem; */
  /* display: flex !important;
  align-items: center !important;
  justify-content: end !important; */
}

.map-filter-container {
  padding: 0.5rem;
  min-width: 350px !important;
}

.map-card-panel-container {
  height: calc(100vh - 240px);
}

.map-card-panel {
  max-height: calc(100vh - 400px);
  overflow-y: auto;
}

.map-legend {
  background-color: #ffffff;
  color: var(--ayvens-theme-brand-secondary-400, #0f3549);
  padding: 1rem;
  border: 1px solid #0f3549;
  border-radius: 0.75rem;
}

.main-navigation-container {
  background-color: #ffffff;
  border-bottom: 0.0625rem solid #c8ced2;
  -webkit-box-shadow: 0 4px 6px -6px #222;
  -moz-box-shadow: 0 4px 6px -6px #222;
  box-shadow: 0 4px 6px -6px #222;
}

.main-navigation-car-info-container {
  background-color: #ffffff;
  border-bottom: 0.0625rem solid #c8ced2;
}

.license-plate-container {
  border-radius: 0.125rem;
  padding: 0.02rem;
  border: 1px solid var(--ayvens-theme-neutral-neutral-400, #bbb);
  background: #fff;
}

.footerContainer {
  background-color: #0f3549;
  color: #ffffff;
  height: 4.125rem;
  a {
    color: #ffffff;
  }
}

.footer-link {
  color: #ffffff !important;
}

.offcanvas-text-wrapper {
  border-radius: 0.3125rem;
  background: #f8f9fa;
  padding: 2rem;
}

.map-info-window-content {
  overflow-y: auto;
  min-height: 140px;
  min-width: 20rem;
}

.drawer-print-title {
  display: none;
}

.navigation-car-button-mobile {
  display: none !important;
}

@media print {
  .drawer-print-title {
    display: block;
    margin-bottom: 1rem;
  }
}

@media only screen and (min-width: 960px) {
  .navigation-filter-button {
    display: none !important;
  }
}

@media only screen and (max-width: 959.9px) {
  .map-full-screen-container {
    padding: 0px !important;
    margin: 0px !important;
  }

  .map-layout-container {
    padding: 0px !important;
    margin: 0px !important;
    /* height: 100% !important; */
  }

  .map-main-content-container {
    display: flex !important;
    flex-direction: row !important;
    flex-wrap: wrap;
    padding: 0px !important;
    margin: 0px !important;
    /* height: 100% !important; */
  }

  .map-container {
    height: 550px;
    order: 1;
    padding: 0px !important;
    margin: 0px !important;
    border-radius: 0px !important;
  }

  .map-card-panel-container {
    /* height: 600px; */
    /* height: 100% !important; */
    order: 2;
    width: 100%;
    padding: 0px !important;
    margin: 0px !important;
  }

  .map-card-panel-header {
    display: none;
  }

  .map-card-panel {
    max-height: 720px;
    overflow-y: auto;
  }

  .map-filter-container {
    display: none;
  }

  .main-footer-container {
    display: none;
  }

  .map-filter-drawer {
    width: 35vh;
  }

  .map-drawer-content-container {
    width: 100%;
  }
}

@media only screen and (max-width: 739.9px) {
  .bg-image {
    background-image: none;
  }

  .card-without-shadow {
    box-shadow: none !important;
  }

  .map-full-screen-container {
    padding: 0px !important;
    margin: 0px !important;
  }

  .map-layout-container {
    padding: 0px !important;
    margin: 0px !important;
    /* height: 100% !important; */
  }

  .map-main-content-container {
    display: flex !important;
    flex-direction: row !important;
    flex-wrap: wrap;
    padding: 0px !important;
    margin: 0px !important;
    /* height: 100% !important; */
  }

  .map-container {
    height: 320px;
    order: 1;
    padding: 0px !important;
    margin: 0px !important;
    border-radius: 0px !important;
  }

  .map-card-panel-container {
    /* height: 600px !important; */
    /* height: 100% !important; */
    order: 2;
    width: 100%;
    padding: 0px !important;
    margin: 0px !important;
  }

  .map-card-panel-header {
    display: none;
  }

  .map-card-panel {
    max-height: 430px;
    overflow-y: auto;
  }

  .map-filter-container {
    display: none;
  }

  .main-footer-container {
    display: none;
  }

  .map-filter-drawer {
    width: 100%;
  }

  .map-drawer-content-container {
    width: 100%;
  }

  .map-info-window-additional-data {
    display: none;
  }

  .map-info-window-content {
    overflow-y: auto;
    min-height: 0px;
    min-width: 0px;
  }

  .map-drawer-navigate-button {
    width: 100% !important;
  }

  .map-drawer-print-button {
    width: 100% !important;
  }

  .map-info-window-content {
    height: 3rem;
  }

  .navigation-car-button {
    display: none !important;
  }

  .navigation-car-button-mobile {
    display: block !important;
  }

  .map-container {
    border-radius: 0rem !important;
  }

  .card-container-body {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .card-container-title {
    margin-bottom: 0.25rem !important;
  }
}
